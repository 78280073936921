/* Spinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full viewport height */
  width: 100%; /* Full viewport width */
  background-color: #fcc477;
}

.spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
