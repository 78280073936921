/* Footer.css */
.footer-section {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fcc477; /* Adjust the background color as needed */
  color: #181818; /* Adjust the text color as needed */
  padding: 10px; /* Add padding to the footer */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy-policy {
  margin-bottom: 2vh;
}

.footer-separator {
  margin-top: 4vh;
  border-top: 4px solid whitesmoke;
  border-radius: 25px;
  margin-bottom: 2vh;
}
.footer-quick-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.footer-link {
  margin: 10px;
  font-size: calc(16px + 0.390625vw);
  color: #181818;
}

.footer-forms {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 30px; /* Adjust the gap between social icons if needed */
  margin-bottom: 40px;
  justify-content: center;
}

.facebook-footer-image {
  width: 48px; /* Set the width of the images to 30px */
  height: auto; /* Maintain aspect ratio */
}

.linkedin-footer-image {
  width: 55px;
}

.footer-brandname {
  font-size: calc(12px + 0.390625vw);
}

@media screen and (max-width: 529px) {
  .footer-link {
    font-size: calc(12px + 0.390625vw);
  }
}

@media screen and (max-width: 529px) {
  .footer-link {
    font-size: calc(12px + 0.390625vw);
    margin: 6px;
  }
}
@media screen and (max-width: 404px) {
  .footer-link {
    font-size: calc(10px + 0.390625vw);
  }
}
