@media screen and (max-width: 991px) {
  .nav-links-container {
    margin-top: 10px; /* Add margin to separate the links from the logo */
  }

  .nav-links > * {
    margin-right: 0; /* Remove the right margin on individual links */
    margin-bottom: 10px; /* Add bottom margin for spacing between links */
  }

  .logo-img {
    width: 50px;
    height: auto;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(250, 226, 189, 0.1);
  backdrop-filter: blur(5px); /* Adjust the blur radius as desired */
  -webkit-backdrop-filter: blur(5px); /* For Safari support */
}

.navbar-logo-inside {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  max-width: fit-content;
  color: #181818;
}

.logo-img {
  height: 75px;
  width: auto;
}

.navbar-title {
  font-size: 2rem;
  text-decoration: none;
}

.navbar-title:visited {
  color: inherit;
}

.nav-links-container {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-links {
  margin-left: 8px;
}

.news-link {
  border: 1.5px solid #181818;
  padding: 10px 15px;
  margin-left: 8px;
}

.contact-us-link {
  border: 1.5px solid rgb(24, 24, 24);
  padding: 10px 15px;
  background-color: #181818;
}

.news-link,
.contact-us-link {
  font-weight: 500;
  border-radius: 25px;
}

.contact-us-link:hover {
  background-color: rgba(0, 0, 0, 0.65);
  transition: background-color 0.3s ease;
}

.news-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.nav-links > .contact-us-link,
.nav-links > .contact-us-link:visited,
.nav-links > .contact-us-link:link {
  color: whitesmoke;
}

.nav-links > *,
.nav-links > *:visited,
.nav-links > *:link {
  text-decoration: none;
  margin-right: 15px;
  color: inherit;
}

.nav-links > .mission-link,
.nav-links > .solutions-link {
  position: relative;
  overflow: hidden;
}

.nav-links > .mission-link:after,
.nav-links > .solutions-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: rgba(240, 144, 115, 255);
  transition: width 0.3s ease;
}

.nav-links > .mission-link:hover:after,
.nav-links > .solutions-link:hover:after {
  width: 100%;
}
