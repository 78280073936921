  label{
   display:flex;
    flex-direction:column;
    width:40px;
    cursor:pointer;
    margin-right: 20px;
  }
  
  label span{
    background: #000000;
    border-radius:10px;
    height:4px;
    margin: 5px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
  
  }
  
  span:nth-of-type(1){
    width:50%;
    
  }
  
  span:nth-of-type(2){
    width:100%;
  }
  
  
  span:nth-of-type(3){
    width:75%;
   
  }
  
  .burger-input {
    display: none;
  }
  
  
  .burger-input.checkbox ~ span:nth-of-type(1){
    transform-origin:bottom;
    transform:rotatez(45deg) translate(8px,0px)
  }
  
  
  .burger-input.checkbox ~ span:nth-of-type(2){
    
    transform-origin:top;
    transform:rotatez(-45deg)
  }
  
  
  .burger-input.checkbox ~ span:nth-of-type(3){
    
    transform-origin:bottom;
    width:50%;
    transform: translate(18px,-9px) rotatez(45deg);
  
  }
  .hamburesz-menu {
    position: relative;
    display: inline-block;
    
  }
  

.menu-container {
    position: fixed;
    margin: 0;
    padding: 0;
    border: none;
    top: 70px;
    right: 0px;
    width: 300px;
    font-weight:400;
    font-size: larger;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(30px + 0.390625vw);
    z-index: 100;
    background-color: rgb(250, 226, 189);
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    transform: translateX(100%);
    
}

.slide-in {
        opacity: 1;
        transform: translateX(0%);
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }

  .hamburger-links,
  .hamburger-links > *,
  .hamburger-links > *:visited,
  .hamburger-links > *:link
  {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 25px;
    list-style: none;
    text-decoration: none;
    margin-right: 15px;
    color: inherit;
}

.menu-list {
  width: 100%;
}

.hamburger-links.selected {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s;
  width: 100%;
}
